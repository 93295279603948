import http from "@/utils/request";

// post
export function login(data) {
    return http({
        method: "post",
        url: "/webLogin.nd",
        data
    });
}

// 获取验证码
export function imgCode() {
    return http({
        method: 'get',
        url: '/checkImg2.nd'
    })
}

// 验证图形验证码是否正确
export function testImgCode(params){
    return http({
        method:'get',
        url:'/checkImgCode.nd',
        params
    })
}

// 原手机可用点击下一步匹配账号和手机号并发送短信验证码
// CIS-9455	pc- 验证码无效-未修复（修改接口）
export function getMessageCode(data) {
    return http({
        method: 'post',
        url: '/pwdSendMsmCode.nd',
        data
    })
}
// 原手机不可以用修改账号密码
export function phoneNotUse(data) {
    return http({
        method: 'post',
        url: '/verifyQuestion.nd',
        data
    })
}