import {
    imgCode,
    //原手机可用
    getMessageCode,
    // 原手机不可用
    phoneNotUse,
    // 验证图形验证码是否可用
    testImgCode
} from './api';

export default {
    name: 'findPassword',
    data() {
        return {
            canUse: true,
            // 验证码倒计时
            codevalue: '获取验证码',
            clickCancle: false,
            // 图形验证码
            imgURL: '',
            loginerrorPH: false,
            // 原手机可用
            loginNum: {
                account: '',
                phone: ''
            },
            // 原手机不可用
            bussiness: {
                custLoginName: '',
                custFullName: '',
                custLegalPerson: ''
            },
            // 弹框
            modelhide: false,
            modelInfo: '',
            imgCode: '',
            errorMSG: '',
            errorPhone: ''
        }
    },
    mounted() {
        this.getIdentifyingCode();
        document.querySelector('body').setAttribute('style', 'background-color:#F0F3F7')
    },
    beforeDestroy() {
        document.querySelector('body').removeAttribute('style', 'background-color:#FFFFFF')
    },
    methods: {
        //  跳转登录页
        tologins() {
            this.$router.push('/login')
        },
        tabChagne(data) {
            this.canUse = data;
        },
        // 图片验证码
        getIdentifyingCode() {
            imgCode().then(res => {
                this.imgURL = res.data.img;
            })
        },
        // 获取手机验证码
        getCode() {
            var totaltime = 6
            this.clickCancle = true
            var times = setInterval(() => {
                totaltime--;
                this.codevalue = '已发送' + totaltime + 's'
                if (totaltime == 0) {
                    clearInterval(times);
                    this.codevalue = '获取验证码';
                    this.clickCancle = false;
                };
            }, 1000)
        },
        // 原手机可用 下一步跳转重置密码
        tologin() {
            let phone = this.loginNum.phone;
            let data = this.loginNum
            if (this.loginNum.account == '') {
                // this.$message.warning('商家账号不能为空')
                this.errorMSG = '商家账号不能为空'
                return;
            } else if (this.loginNum.phone == '') {
                // this.$message.warning('手机号不能为空')
                this.errorMSG = '';
                this.errorPhone = '手机号不能为空'
                return;
            } else if (!(/^1[3456789]\d{9}$/.test(phone))) {
                this.errorPhone = '';
                this.errorMSG = '';
                this.errorPhone = '手机号格式错误'
                return;
            }
            let params = {
                imgCode: this.imgCode,
                ...this.loginNum
            }
            this.codeTrue(params)
            // testImgCode(params).then(res => {
            //     if (res.data == 'N') {
            //         this.$message.warning('验证码错误，请重新输入')
            //         return;
            //     } else {
                    
            //     }
            // })


        },
        // 验证码正确之后
        codeTrue(data) {
            getMessageCode(data).then(res => {
                if (res.data.status == 'true') {
                    sessionStorage.setItem('phoneCanUse', JSON.stringify(this.loginNum))
                    this.$router.push('getCode')
                    this.getIdentifyingCode()
                } else if (res.data.msg == '账号手机号不匹配！') {
                    this.modelInfo = res.data.msg
                    this.modelhide = true;
                    this.getIdentifyingCode()
                } else {
                    this.$message.warning(res.data.msg)
                    this.getIdentifyingCode()
                }
            })
        },


        // 原手机不可用
        toreset() {
            if (this.bussiness.custLoginName == '') {
                this.$message.warning('请输入商家登录账号')
                return;
            } else if (this.bussiness.custFullName == '') {
                this.$message.warning('请输入商家全称')
                return;
            } else if (this.bussiness.custLegalPerson == '') {
                this.$message.warning('请输入商家法人姓名');
                return;
            }
            let data = this.bussiness;
            phoneNotUse(data).then(res => {
                if (res.data == "Error") {
                    // this.$message.warning('商家信息错误！')
                    this.modelInfo = '商家信息错误！'
                    this.modelhide = true;
                } else if (res.data == "Success") {
                    sessionStorage.setItem('phoneNoUse', JSON.stringify(this.bussiness));
                    this.$router.push('reset')
                }
            })

        },
        // 弹框
        modelHide() {
            this.modelhide = false;
        }
    }
}